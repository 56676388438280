import {useEffect, useState} from "react";

import config from "../../config";
import {Link, useLocation} from "react-router-dom";

import {useAppInfo} from "../../helpers/hooks/common-hook";
import {appNavigation, appsNavigation, userSettingsNavigation,} from "./const";
import DashboardRouter from "../dashboard-router";
import {createAppBasePath} from "../../consts/route-consts";
import { Navbar, Sidebar } from "flowbite-react";
import SettingsDialog from "../client-settings";
import LogoImage from "../../assets/images/chatapp.png";
import {getSettingsData} from "../client-settings/client-settings-data";
import { Button } from "flowbite-react";

function LinkItem({navigation}) {
    const appInfo = useAppInfo();
    let navPath = navigation.href;
    // if (appInfo && !navigation.root) {
    //     navPath = `${createAppBasePath(appInfo.id)}${navPath}`;
    // }
    if (navigation.name.includes('Chat')) {
        return <Link onClick={()=> {
            const settings = getSettingsData()
            window.open(  `https://ep-workflow.defai.chat/web-chat-plugin?url=${settings.url}&token=${settings.token}`,)
        }} >{navigation.name}</Link>
    } else {
        return <Link to={navPath} >{navigation.name}</Link>
    }

}

export default function DashboardPage() {
    const location = useLocation();
    const appInfo = useAppInfo();
    const [navigations, setNavigations] = useState([]);
    const [selectedNav, setSelectedNav] = useState(0);
    const [bannerMessage, setBannerMessage] = useState(null);

    const [openConfirmPublishDialog, setOpenConfirmPublishDialog] =
        useState(false);

    const [openSettingsDialog, setOpenSettingsDialog] = useState(false);

    function isChatPluginPath() {
        const {pathname} = location;
        return pathname.includes("web-chat-plugin");
    }

    function findRelevantNavigationIndex(navigations, currentPath) {
        try {
            navigations = navigations.map((nav) => nav.href);

            // Split the current path
            const currentPathParts = currentPath.split("/");

            for (let i = 0; i < navigations.length; i++) {
                const navParts = navigations[i].split("/");

                let isMatch = true;
                // Compare up to the shorter length of the two paths
                const comparisonLength = Math.min(
                    currentPathParts.length,
                    navParts.length
                );
                for (let j = 0; j < comparisonLength; j++) {
                    // console.log("currentPathParts[j]", currentPathParts[j])
                    // console.log("navParts[j]", navParts[j])
                    if (currentPathParts[j] !== navParts[j]) {
                        isMatch = false;
                        break;
                    }
                }

                if (isMatch) {
                    return i;
                }
            }

            return -1; // If no match is found
        } catch (e) {
            return -1;
        }
    }

    async function setupNavigation() {
        const {pathname} = location;
        let _navigations;

        // if (appInfo && pathname.includes('app')) {
        //     _navigations = appNavigation
        // } else if (isUserSettingPath()) {
        //     _navigations = userSettingsNavigation;
        // } else {
        //     _navigations = appsNavigation;
        // }
        _navigations = appsNavigation;

        setSelectedNav(findRelevantNavigationIndex(_navigations, pathname));

        setNavigations([..._navigations]);
    }

    const initFetch = () => {
        setupNavigation();
    };

    useEffect(() => {
        initFetch();
    }, []);

    useEffect(() => {
        initFetch();
    }, [appInfo, location]);

    const redirectToWebPlugin = async () => {
        window.open(
            `${config.WEB_PLUGIN_URL}?app_id=${appInfo.appId}&test_mode=1`,
            "_blank"
        );
    };

    const openPublishConfirm = () => {
        setOpenConfirmPublishDialog(true);
    };

    const handleOpenSettings = () => {
        setOpenSettingsDialog(true);
    }

    const handleCloseSettings = async state => {
        setOpenSettingsDialog(false)
    }

    if (isChatPluginPath()) {
        return<div className="flex flex-col h-screen">
        <main className="flex-grow overflow-y-auto bg-gray-100 bg-white">
         <DashboardRouter/>
        </main>
        </div>
    }

    const settings = getSettingsData()
    return (
        <div className="flex flex-col h-screen">
            {/* Header */}
            {/*<header className="bg-blue-500 text-white p-4 flex-shrink-0 fixed w-full top-0 z-10">*/}
            {/*    <h1 className="text-xl">Chat-Flow</h1>*/}
            {/*</header>*/}
            <Navbar fluid rounded className="p-4 flex-shrink-0 fixed w-full top-0 z-10 border-b-2 bg-gray-50">
                <Navbar.Brand as={Link} href="">
                    <img src={LogoImage} className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
                    {/*<span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Chat-Flow</span>*/}
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Navbar.Link href="#" onClick={()=>window.open(  `/web-chat-plugin?url=${settings.url}&token=${settings.token}`,)} active>
                        <Button outline gradientDuoTone="purpleToBlue">Test ChatBot</Button>
                    </Navbar.Link>
                </Navbar.Collapse>
            </Navbar>

            {openSettingsDialog &&
                <SettingsDialog
                    open={openSettingsDialog}
                    onClose={handleCloseSettings}
                />
            }

            {/* Main Content */}
            <div className="flex flex-grow pt-16 ">
                {/* Left Menu */}
                {/*<aside className="bg-gray-50 text-white w-64 flex-shrink-0 overflow-y-auto h-full fixed top-16 bottom-16">*/}
                {/*    <ul className="p-4">*/}
                {/*        {navigations.map((navigation, key)=>*/}
                {/*            <LinkItem key={key} navigation={navigation} />*/}
                {/*        )}*/}
                {/*    </ul>*/}
                {/*</aside>*/}

                <Sidebar className="w-64 flex-shrink-0 overflow-y-auto h-full fixed top-16 bottom-16" aria-label="Default sidebar example">
                    <Sidebar.Items>
                        <Sidebar.ItemGroup>
                            {navigations.map((navigation, key)=>
                                <Sidebar.Item >
                                    <LinkItem key={key} navigation={navigation} />
                                </Sidebar.Item>
                            )}

                        </Sidebar.ItemGroup>
                    </Sidebar.Items>
                </Sidebar>

                {/* Content Section */}
                <main className="flex-grow overflow-y-auto p-4 bg-gray-100 ml-64 bg-white">
                    <DashboardRouter/>
                </main>
            </div>

            {/* Footer */}
            {/*<footer className="bg-blue-500 text-white p-4 flex-shrink-0 fixed w-full bottom-0">*/}
            {/*    <p className="text-center">AIFocal</p>*/}
            {/*</footer>*/}
        </div>
    );
}
